
import userApi from "./apis/userApi"
import menuApi from "./apis/menuApi"
import miscApi from "./apis/miscApi"
import flowApi from "./apis/flowApi"
import reviewApi from "./apis/reviewApi"
import auditionApi from "./apis/auditionApi"
import enforcementApi from "./apis/enforcementApi"

const api = {
  userApi,
  menuApi,
  miscApi,
  flowApi,
  reviewApi,
  auditionApi,
  enforcementApi
}

export default api;