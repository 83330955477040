import axios from "../axios"

const miscApi = {
    getRegionTree() {
        return axios({
            url: "http://221.10.90.154:8085/eca/TRegion/GetManageToolMenu",
            method: "post",

        })
    },
    getSCRegionTree() {
        return axios({
            url: "sceua/CibcRegion/QuerySC",
            method: "post",
            data: {
                Value: "",
                StartIndex: 0,
                Page: 1,
                Rows:99,
            }

        })
    },
    getNotifyStatus() {
        return axios({
            url: "sceua/ETrainVideoRegionCompany/CheckCompanyRead",
            method: "post",

        })
    },
    saveFile({ secondPath,  formData, file,fileName }) {
		//secondPath  uappcomid/seqnum
        return axios({
            url: `/gzeua/Upload/file?path=sceua&secondPath=${secondPath}&fileName=${fileName}`,
            method: "post",
            data: formData,
            file,
            processData: false,
            contentType: false,
        })
    },

}
export default miscApi
