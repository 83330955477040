import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import locale from "element-plus/es/locale/lang/zh-cn"
import "./assets/scss/normalize.scss"
import "./assets/scss/elementcustom.scss"
import dayjs from "dayjs"
import * as commonApi from './api/index'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.$httpApi = commonApi
app.use(store).use(router).use(ElementPlus, { locale }).mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}