import axios from "../axios"

const flowApi = {
    getFlowList() {
        return axios({
            url: "sceua/EFlow/QueryFlow",
            method: "post",
            data: {
                Value: "",
                StartIndex: 0,
                Page: 1,
                Rows: 99,
            }
        })
    },
    getFlowStepList(ParentId) {
        return axios({
            url: "sceua/EFlow/QueryFlowStep",
            method: "post",
            data: {
                ParentId,
                Value: "",
                StartIndex: 0,
                Page: 1,
                Rows: 99,
            }

        })
    },
    getAuditItemList(data) {
        return axios({
            url: "sceua/AppraiseAuds/Query",
            method: "post",
            data
        })
    },
    saveUserFlowStep(data) {
        return axios({
            url: `sceua/EUserApp/CreateEditSave`,
            method: "post",
            data
        })
    },
    deleteUserFlowStep(id) {
        return axios({
            url: `sceua/EUserApp/Delete`,
            method: "post",
            params: {
                id
            }
        })
    },

}
export default flowApi
