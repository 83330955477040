import requerst from '../utils/http'

// 用户管理
export const getUserList = (data) => requerst({ url: 'sceua/EIBCUser/Query', method: 'post', data })
// 修改密码
export const saveEditpassword = (data) => requerst({ url: `sceua/Account/editpassword`, method: 'post', data })
// 修改登录名
export const saveReEditLoginName = (data) => requerst({ url: `sceua/EIBCUser/ReEditLoginName?oldLoginName=${data.oldLoginName}&newLoginName=${data.newLoginName}`, method: 'post' })
// 评价指标大项查询
export const getAppraiseAuds = (data) => requerst({ url: 'sceua/AppraiseAuds/Query', method: 'post', data })
// 评价指标大项删除
export const appraiseAudsDelete = (id) => requerst({ url: `sceua/AppraiseAuds/Delete?id=${id}`, method: 'post' })
// 评价指标大项保存
export const createEditSave = (data) => requerst({ url: 'sceua/AppraiseAuds/CreateEditSave', method: 'post', data })
// 评价指标小项查询
export const getAppraiseAudsItem = (data) => requerst({ url: 'sceua/AppraiseAudsItem/Query', method: 'post', data })
// 评价指标小项详情查询
export const getAppQueryItemTree = (id) => requerst({ url: `sceua/AppraiseAudsItem/QueryItemTree?id=${id}`, method: 'post' })
// 评价指标小项删除
export const appraiseAudsDeleteItem = (id) => requerst({ url: `sceua/AppraiseAudsItem/Delete?id=${id}`, method: 'post' })
// 评价指标小项保存
export const createEditSaveItem = (data) => requerst({ url: 'sceua/AppraiseAudsItem/CreateEditSave', method: 'post', data })
// 评价指标小小项查询
export const getAppraiseAudsItemItem = (data) => requerst({ url: `sceua/AppraiseAudsItemItem/Query`, method: 'post', data })
// 评价指标小小项保存
export const createEditSaveItemItem = (data) => requerst({ url: 'sceua/AppraiseAudsItemItem/CreateEditSave', method: 'post', data })
// 评价指标小项删除
export const appraiseAudsDeleteItemItem = (id) => requerst({ url: `sceua/AppraiseAudsItemItem/Delete?id=${id}`, method: 'post' })
// 评价指标类型获取
export const getAppraiseTypeCode = (data) => requerst({ url: 'sceua/Appraise/AppraiseTypeCode', method: 'post', data })
// 工作任务查询
export const getAppraise = (data) => requerst({ url: 'sceua/Appraise/Query', method: 'post', data })
// 工作任务删除
export const delAppraise = (id) => requerst({ url: `sceua/Appraise/Delete?id=${id}`, method: 'post' })
// 工作任务保存
export const saveAppraise = (data) => requerst({ url: 'sceua/Appraise/CreateEditSave', method: 'post', data })
// 工作任务附件
export const flieAppraise = (data) => requerst({ url: `sceua/Upload/file?path=${data.path}&secondPath=${data.secondPath}&fileName=${data.fileName}`, method: 'post', data: data.file })
// 参评企业管理 修改企业基本信息
export const saveUpdateBaseCompany2 = (data) => requerst({ url: 'sceua/CIBCCompany/UpdateBaseCompany2', method: 'post', data })
// 参评企业管理查询
export const getAppraiseCompany = (data) => requerst({ url: 'sceua/AppraiseCompany/Query', method: 'post', data })
// 参评企业管理删除
export const delAppraiseCompany = (id) => requerst({ url: `sceua/AppraiseCompany/Delete?id=${id}`, method: 'post' })
// 参评企业管理保存
export const saveAppraiseCompany = (data) => requerst({ url: 'sceua/AppraiseCompany/CreateEditSave	', method: 'post', data })
// 企业信息查询
export const getEIBCUser = (data) => requerst({ url: 'sceua/EIBCUser/QueryCompany', method: 'post', data })
// 重置密码
export const restAccount = (id) => requerst({ url: `sceua/Account/ReSetPassWord?userId=${id}`, method: 'post' })
// 查询所有企业当前等级得分
export const getQueryAppraisedAll = (data) => requerst({ url: 'sceua/AppraiseCompany/QueryAppraisedAll', method: 'post', data })
// 查询四川行政区划
export const getQuerySC = (data) => requerst({ url: 'sceua/CibcRegion/QuerySC', method: 'post', data })
// 查询四川行政区划(带ID)
export const getQuerById = (id) => requerst({ url: `sceua/CibcRegion/QuerById?rid=${id}`, method: 'post' })
// 查询四川行政区划带 ID 
export const getQueryUserRegionIds = (data) => requerst({ url: 'sceua/EUserApp/QueryUserRegionIds', method: 'post', data })
// 查询当前用户当前关联企业
export const getCurCompanyByCurUserId = (data) => requerst({ url: 'sceua/CIBCCompany/GetCurrentCompanyByCurrentUserId', method: 'post', data })
// 通过ID获取企业信息
export const getComanyByBaseCompanyId = (id) => requerst({ url: `sceua/CIBCCompany/GetComanyByBaseCompanyId?baseCompanyId=${id}`, method: 'post' })
// 保存企业营业执照（盖章）
export const saveCard = (data) => requerst({ url: `https://cibc.wxcs.net.cn/cibc/Company/SaveCard?id=${data.id}&id2=${data.id2}`, method: 'post', data: data.file })
// 保存企业形象照片
export const savePhoto = (data) => requerst({ url: `https://cibc.wxcs.net.cn/cibc/Company/SavePhoto?id=${data.id}&id2=${data.id2}`, method: 'post', data: data.file })
// 查询所有企业行业分类
export const getIndustryTree = (data) => requerst({ url: 'https://cibc.wxcs.net.cn/cibc/LoginWithKey/GetIndustryTree?key=WE8N9D0V8BN40SLFO43P', method: 'post', data })
// 查询管控类型
export const getQueryGKLX = (data) => requerst({ url: 'sceua/CIBCCompany/QueryGKLX', method: 'post', data })
// 查询排污许可管理类型
export const getQueryPWLX = (data) => requerst({ url: 'sceua/CIBCCompany/QueryPWLX', method: 'post', data })
// 查询企业类型
export const getQueryQYLX = (data) => requerst({ url: 'sceua/CIBCCompany/QueryQYLX', method: 'post', data })
// 查询所属街道
export const getQueryRegionLoadList = (data) => requerst({ url: `sceua/CibcRegion/QueryRegionLoadList?xian=${data}`, method: 'post' })
// 保存企业基本信息
export const saveCreateOrEdit = (data) => requerst({ url: 'sceua/CIBCCompany/CreateOrEdit', method: 'post', data })
// 动态评价审核列表查询
export const getQueryForAppraiseAuto = (data) => requerst({ url: 'sceua/AppraiseCompany/QueryForAppraiseAuto', method: 'post', data })

// // 企业动态评价 按公司任务id查询记录
// export const getAppraiseAutoAuditQuery = (data) => requerst({ url: 'sceua/AppraiseAutoAudit/Query', method: 'post', data })

// 企业动态评价 新增审核记录
export const saveAppraiseAutoAudit = (data) => requerst({ url: 'sceua/AppraiseAutoAudit/CreateEditSave', method: 'post', data })
// 文件培训查询
export const getETrainVideoRegionCompany = (data) => requerst({ url: 'sceua/ETrainVideoRegionCompany/Query', method: 'post', data })
// 文件培训查看详情
export const getETrainVideoRegionCompanyDetail = (id) => requerst({ url: `sceua/ETrainVideoRegionCompany/Detail?id=${id}`, method: 'post' })
// 保存企业名称，代码
export const saveUpdateNameCode = (data) => requerst({ url: `sceua/AppraiseCompany/UpdateNameCode`, method: 'post', data })
// 企业动态评价 根据当前用户查询申请记录
export const getAppraiseAutoAuditQueryByUser = (data) => requerst({ url: 'sceua/AppraiseAutoAudit/QueryByUser', method: 'post', data })
// 企业动态评价 删除
export const delAppraiseAutoAudit = (id) => requerst({ url: `sceua/AppraiseAutoAudit/Delete?id=${id}`, method: 'post' })
// 企业动态评价 查看详情
export const getByKey = (id) => requerst({ url: `sceua/AppraiseAutoAudit/GetByKey?id=${id}`, method: 'post' })
// 企业动态评价 按公司任务id查询记录
export const getQueryForAudit = (data) => requerst({ url: 'sceua/AppraiseAutoAudit/QueryForAudit', method: 'post', data })
// 查询县级审核记录
export const getQueryCurrentUserAudits = (data) => requerst({ url: 'sceua/AppraiseAutoAudit/QueryCurrentUserAudits', method: 'post', data })
// 查询市级审核记录
export const getQueryCurrentUserAudits2 = (data) => requerst({ url: 'sceua/AppraiseAutoAudit/QueryCurrentUserAudits2', method: 'post', data })
// 审核人员查询所有的文件
export const getManagerQuery = (data) => requerst({ url: 'sceua/ETrainVideoRegionCompany/ManagerQuery', method: 'post', data })
// 查询当前企业参评类型NC22项排污企业 SC17项服务企业
export const getCompanyAppraiseType = (id) => requerst({ url: `sceua/AppraiseCompany/GetCompanyAppraiseType?appcomId=${id}`, method: 'post' })
// 查询审核行政处罚
export const getQueryALL = (data) => requerst({ url: 'sceua/AppraiseCompanyRequestRepair/QueryALL', method: 'post', data })
// 行政处罚修复，市级审核 审核人员查询所有
export const getQueryALL2 = (data) => requerst({ url: 'sceua/AppraiseCompanyRequestRepair/QueryALL2', method: 'post', data })
// 查询企业行政处罚
export const getQueryByUser = (data) => requerst({ url: 'sceua/AppraiseCompanyRequestRepair/QueryByUser', method: 'post', data })
// 行政处罚删除
export const delAppraiseCompanyRequestRepair = (id) => requerst({ url: `sceua/AppraiseCompanyRequestRepair/Delete?id=${id}`, method: 'post' })
// 市级查询
export const getQueryForAudit3 = (data) => requerst({ url: 'sceua/AppraiseAutoAudit/QueryForAudit3', method: 'post', data })
// 异议根据当前用户查询申请记录
export const getAppQueryByUser = (data) => requerst({ url: 'sceua/AppraiseQuestion/QueryByUser', method: 'post', data })
// 异议新增动态记录
export const saveAppCreateEditSave = (data) => requerst({ url: 'sceua/AppraiseQuestion/CreateEditSave', method: 'post', data })
// 异议查询审核
export const getAppQueryForAudit = (data) => requerst({ url: 'sceua/AppraiseQuestion/QueryForAudit', method: 'post', data })
// 异议回复保存
export const saveAppSaveAudit = (data) => requerst({ url: 'sceua/AppraiseQuestion/SaveAudit', method: 'post', data })
// 异议保存附件
export const saveAppSaveUpLoadFile = (data) => requerst({ url: `sceua/Upload/file?path=${data.path}&secondPath=${data.secondPath}&fileName=${data.fileName}`, method: 'post', data: data.file })
// 异议查看详情
export const getAppGetByKey = (id) => requerst({ url: `sceua/AppraiseQuestion/GetByKey?id=${id}`, method: 'post' })
// 异议删除
export const delAppDelete = (id) => requerst({ url: `sceua/AppraiseQuestion/Delete?id=${id}`, method: 'post' })
// 审核分配查询
export const getAppQueryALL3 = (data) => requerst({ url: 'sceua/AppraiseCompanyRequestRepair/QueryALL3', method: 'post', data })
// 审核分配保存
export const saveAppSetRegion = (data) => requerst({ url: `sceua/AppraiseCompanyRequestRepair/SetRegion?id=${data.id}&regionId=${data.regionId}&regionName=${data.regionName}`, method: 'post', data })
// 企业动态评价查询
export const getCompanyAppraiseQuery = (data) => requerst({ url: 'sceua/AppraiseCompany/CompanyAppraiseQuery', method: 'post', data })
// 到期确认查询
export const getDQQueryALL = (data) => requerst({ url: 'sceua/AppraiseAutoGetDate/QueryALL', method: 'post', data })
// 到期确认详情
export const getDQGetByKey = (id) => requerst({ url: `sceua/AppraiseAutoGetDate/GetByKey?id=${id}`, method: 'post' })
// 到期确认保存
export const repairAutoAuditItem = (id, no) => requerst({ url: `sceua/AppraiseAutoAudit/RepairStepEnd?appraiseComId=${id}&audNo=${no}`, method: 'post' })
// 编辑集团代码
export const saveUpdateProNameCode = (data) => requerst({ url: `sceua/AppraiseCompany/UpdateProNameCode`, method: 'post', data })
// 单项审核数据修复
// export const saveDQSaveAudit = (data) => requerst({ url: `sceua/AppraiseAutoGetDate/SaveAudit?auditContent=${data.auditContent}&score=${data.score}&id=${data.id}`, method: 'post', data })
// 测试导出
// export const getExportBlob = () => requerst({ url: `sceua/AppraiseCompany/ExportToExcel?regionId=2268&appraiseId=2`, method: 'get', responseType: 'blob' })

// 问卷反馈查询
export const getEAppraiseAutoApply = (id) => requerst({ url: `sceua/EAppraiseAutoApply/Query?appraiseComId=${id}`, method: 'post' })
// 问卷反馈保存
export const saveEAppraiseAutoApply = (data) => requerst({ url: `sceua/EAppraiseAutoApply/CreateEditSave`, method: 'post', data })
// 问卷反馈附件
export const saveSaveUpLoadFile = (data) => requerst({ url: `sceua/Upload/file?path=${data.path}&secondPath=${data.secondPath}&fileName=${data.fileName}`, method: 'post', data: data.file })
// 企业应用列表
export const getQueryApplyView = (data) => requerst({ url: 'sceua/EAppraiseAutoApply/QueryApplyView', method: 'post', data })
// 发送短信
export const saveSendRegionSms = (reg, msg) => requerst({ url: `sceua/EUnit/SendRegionSms?reg=${reg}&msg=${msg}`, method: 'post' })
// 发送到省平台
export const saveSubmitToProvince = (aid) => requerst({ url: `sceua/AppraiseAutoAudit/SubmitToProvince?appraiseComId=${aid}`, method: 'post' })
// 更新动态审核分数
export const saveRefreshScore3 = (aid) => requerst({ url: `sceua/AppraiseCompany/RefreshScore3?appcomid=${aid}`, method: 'post' })
// 查询企业短信
export const getCompanyMessages = (data) => requerst({ url: `sceua/MobileMessage/QueryCompanyMessages`, method: 'post', data })
// 查询短信发送的数量
export const getSmsDateReport = (data) => requerst({ url: `sceua/MobileMessage/QuerySmsDateReport?time1=${data.time1}&time2=${data.time2}`, method: 'post' })
// 企业分数记录
export const getComScores = (data) => requerst({ url: 'sceua/AppraiseComScores/Query', method: 'post', data })
// 责令限期改正通知书
export const getZLGZINFO = (data) => requerst({ url: `sceua/EpbCfxx/SearchZLGZINFO`, method: 'post', data })
// 立案审批表
export const getLianspinfo = (data) => requerst({ url: `sceua/EpbCfxx/SearchLianspinfo`, method: 'post', data })
// 行政处罚决定书
export const getXzcfjdinfo = (data) => requerst({ url: `sceua/EpbCfxx/SearchXzcfjdinfo`, method: 'post', data })
// 查询企业分数记录
export const getComScoresY3 = (data) => requerst({ url: `sceua/AppraiseComScoresY3/Query`, method: 'post', data })
// 查询企业不对外公示
export const getComscoresRef = (data) => requerst({ url: `/sceua/AppraiseComscoresRef/Query`, method: 'post', data })
// 添加企业不对外公示
export const saveComscoresRef = (id) => requerst({ url: `/sceua/AppraiseComscoresRef/Add?appComId=${id}`, method: 'post' })
// 删除企业不对外公示
export const delComscoresRef = (id) => requerst({ url: `/sceua/AppraiseComscoresRef/Delete?id=${id}`, method: 'post' })
// 获取环境信用报告
export const getExportAppraisePdf = (id) => requerst({ url: `/sceua/AppraisePdf/ExportAppraisePdf?appComId=${id}`, method: 'get' })
// 查询企业，历来情况
export const getQueryScoreForGroup = (id) => requerst({ url: `/sceua/AppraiseComScores/QueryScoreForGroup?id=${id}`, method: 'post' })
// 视频培训编辑保存
export const saveVideoRegionCompany = (data) => requerst({ url: `/sceua/ETrainVideoRegionCompany/Update`, method: 'post', data })
