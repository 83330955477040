import axios from "../axios";
const enforcementApi = {
	// getfinementManualItemList(data) {
	//     return axios({
	//         url: "sceua/EpbCfxx/Search",
	//         method: "post",
	//         data,
	//     });
	// },
	// setFinementManualItemConfirmed(id,status) {
	//     return axios({
	//         url: `sceua/EpbCfxx/SetDeal`,
	//         method: "post",
	//         params: { id ,status}
	//     })
	// },
	getEnforcementCasesList(data) {
		return axios({
			url: `sceua/Epba/SearchLaxx`,
			method: "post",
			data
		})
	},
	setEnforcementCasesItemConfirmed(id, status) {
		return axios({
			url: `sceua/Epba/SetDeal`,
			method: "post",
			params: {
				id,
				status,
				tableName: "epb_laxx"
			}
		})
	},
	getEnforcementRecordList(data) {
		return axios({
			url: `sceua/EpbaSingle/SearchMainData`,
			method: "post",
			data
		})
	},

	getEnforcementRecordDetailByID(id) {
		return axios({
			url: `sceua/EpbaSingle/GetDataById`,
			method: "post",
			params: {
				id
			}
		})
	},
	setEnforcementRecordItemStatus(id, status, tableName) {
		return axios({
			url: `sceua/EpbaSingle/SetDeal`,
			method: "post",
			params: {
				id,
				status,
				tableName
			}
		})
	},

	getEpbaCfxx(data) {
		return axios({
			url: `sceua/EpbCfxx/Search`,
			method: "post",
			data
		})
	},

	setEpbaCfxxDeal(id, status) {
		return axios({
			url: `sceua/EpbCfxx/SetDeal`,
			method: "post",
			params: {
				id,
				status
			}
		})
	},
	
	getEpbaLaxx(data) {
		return axios({
			url: `sceua/Epba/SearchLaxx`,
			method: "post",
			data
		})
	},
}

export default enforcementApi;
