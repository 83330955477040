import axios from "../axios";

const reviewApi = {
  setConfirmDate(appraiseComId) {
    return axios({
      url: `sceua/AppraiseCompany/SetConfirmBookDate`,
      method: "post",
      params: { appraiseComId },
    });
  },

  getReviewListByCompany(data) {
    return axios({
      url: "sceua/AppraiseCompany/CompanyAppraiseQuery",
      method: "post",
      data,
    });
  },
  getReviewFormByID(appraiseComId) {
    return axios({
      url: `sceua/AppraiseCompany/GetAppraiseCompanyRequest`,
      method: "post",
      params: { appraiseComId },
    });
  },
  submitReviewItem(type, requestId, dataObj) {
    let url =
      type == "normal"
        ? `sceua/AppraiseCompany/SaveItemC`
        : `sceua/AppraiseCompany/SaveItemS`;
    const dataObjAlt = { ...dataObj,Serial:dataObj.Serial.toString()};
    if (
      dataObjAlt.Selected instanceof Array ||
      dataObjAlt.Selected instanceof Object
    ) {
      dataObjAlt.Selected = JSON.stringify(dataObjAlt.Selected);
    }else{
        dataObjAlt.Selected =dataObjAlt.Selected.toString();
    }
    if (
      dataObjAlt.Inputs instanceof Array ||
      dataObjAlt.Inputs instanceof Object
    ) {
      dataObjAlt.Inputs = JSON.stringify(dataObjAlt.Inputs);
    }
    if (dataObjAlt.Item instanceof Array || dataObjAlt.Item instanceof Object) {
      dataObjAlt.Item = JSON.stringify(dataObjAlt.Item);
    }
    if (
      dataObjAlt.FileUrl instanceof Array ||
      dataObjAlt.FileUrl instanceof Object
    ) {
      dataObjAlt.FileUrl = JSON.stringify(dataObjAlt.FileUrl);
    }

    return axios({
      url,
      method: "post",
      data: {
        requestId,
        ...dataObjAlt,
      },
    });
  },
  submitReviewItemOld(requestId, path, paraObj) {
    console.info("submit without data");
    return axios({
      url: `sceua/AppraiseCompany/${path}`,
      method: "post",
      data: {
        requestId,
        ...paraObj,
      },
    });
  },
  submitReviewForm(appraiseComId) {
    return axios({
      url: `sceua/AppraiseCompany/SubmitToAudit`,
      method: "post",
      params: {
        appraiseComId,
      },
    });
  },
  saveFileOld({ appcomid, appcomappid, formData, file }) {
    return axios({
      url: `sceua/AppraiseCompany/SaveComAppFile?appcomid=${appcomid}&appcomappid=${appcomappid}`,
      method: "post",
      data: formData,
      file,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      processData: false,
    });
  },
  saveFile(data) {
    return axios({
      // url: `sceua/AppraiseCompany/SaveComAppFile?appraiseComStepId=${appraiseComStepId}&appcomid=${appcomid}`,
      url: `sceua/Upload/file?path=${data.path}&secondPath=${data.secondPath}&fileName=${data.fileName}`,
      method: "post",
      data: data.file,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
      processData: false,
    });
  },
  saveFileAlt(data) {
    return axios({
      url: `sceua/Upload/file?path=Appraise&secondPath=${data.secondPath}&fileName=${data.fileName}`,
      method: "post",
      data: data.formData,
      file: data.file,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
      processData: false,
    });
  },
};
export default reviewApi;
