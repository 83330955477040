import axios from "../axios"

const reviewApi = {

	getAllAuditions(data) {
		return axios({
			url: `sceua/Appraise/Query`,
			method: "post",
			data
		})
	},
	getCompanyStep(data) {
		return axios({//
			url: `/sceua/AppraiseCompany/GetCurrentCompanyFlowStep`,
			method: "post",
			data
		})
	},
	getAllStepList(data) {
		return axios({//
			url: `/sceua/EFlow/QueryFlowStep`,
			method: "post",
			data
		})
	},
	getAuditionStep(appraiseId) {
		return axios({
			url: `sceua/Appraise/QueryStep`,
			method: "post",
			params: {
				appraiseId
			}
		})
	},
	getCompanyScoreDetail(namecode) {
		return axios({
			url: `sceua/AppraiseCompany/GetCompanyScoreDetail`,
			method: "post",
			params: {
				namecode
			}
		})
	},
	getCompanyAuditScoreDetail(namecode) {
		return axios({
			url: `sceua/AppraiseCompany/GetCompanyScoreDetail2`,
			method: "post",
			params: {
				namecode
			}
		})
	},

	getAuditionStepList(ParentId) {
		return axios({
			url: `sceua/EFlow/QueryFlowStep`,
			method: "post",
			data: {
				ParentId,
				Order: "asc",
				Page: 1,
				Rows: 10,
			}
		})
	},

	getAuditionList(data) {
		return axios({
			url: "sceua/AppraiseCompany/QueryForAppraise",
			method: "post",
			data
		})
	},
	getProvienceAuditHistory(data) {
		return axios({
			url: "sceua/AppraiseRecord/Query",
			method: "post",
			data
		})
	},
	getAuditionListForHistory(data) {
		return axios({
			url: "sceua/AppraiseCompany/QueryAppraisedAll",
			method: "post",
			data
		})
	},
	getAuditedList(data) {
		return axios({
			url: `sceua/AppraiseCompany/QueryUserAppraised`,
			method: "post",
			data
		})
	},
	

	getReviewFormByID(appraiseComId) {
		return axios({
			url: `sceua/AppraiseCompany/GetAppraiseCompanyRequest`,
			method: "post",
			params: {
				appraiseComId
			}
		})
	},
	saveAuditionItem(data) {
		return axios({
			url: `sceua/AppraiseCompany/SaveAudsItem25`,
			method: "post",
			data
		})
	},
	saveAuditionItemV2(data) {
		return axios({
			url: `sceua/AppraiseCompany/SaveAudsItemList`,
			method: "post",
			data
		})
	},
	deleteAuditionItem(itemid) {
		return axios({
			url: `sceua/AppraiseCompany/DeleteAudsItem`,
			method: "post",
			params: {
				itemid
			}
		})
	},

	saveAuditionResult(appcomid) {
		return axios({
			url: `sceua/AppraiseCompany/SubmitAudsItem`,
			method: "post",
			params: {
				appcomid
			}
		})
	},

	advanceAuditionStep(unitappcomids) {
		return axios({
			url: `sceua/AppraiseCompany/ForwardStep`,
			method: "post",
			params: {
				unitappcomids
			}
		})
	},
	bulkAdvanceAuditioon(unitappcomids) {
		return axios({
			url: `sceua/AppraiseCompany/BatSubmitAudsItem`,
			method: "post",
			params: {
				unitappcomids
			}
		})
	},

	revokeAuditForm(appcomid, remark = "") {
		return axios({
			url: `sceua/AppraiseCompany/BackStep`,
			method: "post",
			params: {
				appcomid,
				remark
			}
		})
	},
	changeRemarkText(id, remark = "") {
		return axios({
			url: `sceua/AppraiseCompany/UpdateRemark`,
			method: "post",
			data: {
				id,
				remark
			}
		})
	},
	publicateAuidtForm(list, type) {
		return axios({
			url: `sceua/AppraiseCompany`,
			method: 'post',
			params: {
				unitappcomids: list,
				gsgb: type
			}
		})
	},

	getAuditHistoryByID(appcomid) {
		return axios({
			url: `sceua/AppraiseCompany/QueryCurrAudsItem`,
			method: "post",
			params: {
				appcomid
			}
		})
	},

	getLatestAuditHistoryByID(appcomid) {
		return axios({
			url: `sceua/AppraiseCompany/QueryAudsItemCompanyShow`,
			method: "post",
			params: {
				appcomid
			}
		})
	},
	getAuditionMainList(data) {
		return axios({
			url: `sceua/AppraiseAuds/Query`,
			method: "post",
			data
		})
	},
	getAuditionMainListByUser(appComId) {
		return axios({
			url: `sceua/AppraiseAuds/QueryByUser`,
			method: "post",
			params: {
				appComId
			}
		})
	},
	getAuditionSubList(data) {
		return axios({
			url: `sceua/AppraiseAudsItem/Query`,
			method: "post",
			data
		})
	},
	saveFile({
		appraiseComStepId,
		appcomid,
		formData,
		file
	}) {
		return axios({
			url: `sceua/AppraiseCompany/SaveComAppFile?appraiseComStepId=${appraiseComStepId}&appcomid=${appcomid}`,
			method: "post",
			data: formData,
			file,
			headers: {
				'Content-Type': "multipart/form-data"
			},
			processData: false,
		})
	},
	//appeals=========================
	getAppealList(data) {
		return axios({
			url: `sceua/AppraiseCompanyAppeal/Query`,
			method: 'post',
			data
		})
	},
	getAppealListByFormID(data) {
		return axios({
			url: `sceua/AppraiseCompanyAppeal/QueryByAppraiseComId`,
			method: 'post',
			data
		})
	},
	getAppealByID(id) {
		return axios({
			url: `sceua/AppraiseCompanyAppeal/GetByKey`,
			method: 'post',
			params: {
				id
			}
		})
	},
	saveAppealFile({
		appcomid,
		formData,
		file
	}) {
		return axios({
			url: `sceua/AppraiseCompanyAppeal/SaveComAuditFile?appcomid=${appcomid}`,
			method: "post",
			data: formData,
			file,
			headers: {
				'Content-Type': "multipart/form-data"
			},
			processData: false,
		})
	},
	saveAppealItem(data) {
		return axios({
			url: `sceua/AppraiseCompanyAppeal/CreateEditSave`,
			method: "post",
			data
		})
	},
	deleteAppealItem(id) {
		return axios({
			url: `sceua/AppraiseCompanyAppeal/Delete`,
			method: "post",
			params: {
				id
			}
		})
	},
	saveAppealAudit(params) {
		return axios({
			url: `sceua/AppraiseCompanyAppeal/SaveAudit`,
			method: 'post',
			params
		})
	},
	saveAppraiseAutoAudit(data) {
		return axios({
			url: `sceua/AppraiseAutoAudit/CreateEditSave`,
			method: "post",
			data
		})
	},
	getAppraiseAutoAudit(data) {
		return axios({
			url: `sceua/AppraiseAutoAudit/Query`,
			method: "post",
			data
		})
	},
	deleteAppraiseAutoAuditItem(itemid) {
		return axios({
			url: `sceua/AppraiseAutoAudit/Delete`,
			method: "post",
			params: {
				id: itemid
			}
		})
	},
	getAppraiseCompanyRequest(appraiseComId) {
		return axios({
			url: `sceua/AppraiseCompany/GetAppraiseCompanyRequest?appraiseComId=${appraiseComId}`,
			method: "post"
		})
	},
	getExportBlob(data) {
		return axios({
			url: `sceua/AppraiseCompany/ExportToExcel`,
			method: "get",
			responseType: "blob",
			params: {
				...data
			}
		})
	},
	getUserAppraiseCompany() {
		return axios({
			url: `sceua/AppraiseAutoAudit/GetUserAppraiseCompany`,
			method: "post"
		})
	},
	saveCreateEditSave(dataObj) {
		const dataObjAlt = {
			...dataObj
		}
		if (
			dataObjAlt.Com_Selected instanceof Array ||
			dataObjAlt.Com_Selected instanceof Object
		) {
			dataObjAlt.Com_Selected = JSON.stringify(dataObjAlt.Com_Selected);
		}
		if (
			dataObjAlt.Com_Inputs instanceof Array ||
			dataObjAlt.Com_Inputs instanceof Object
		) {
			dataObjAlt.Com_Inputs = JSON.stringify(dataObjAlt.Com_Inputs);
		}
		if (
			dataObjAlt.Com_Item instanceof Array ||
			dataObjAlt.Com_Item instanceof Object
		) {
			dataObjAlt.Com_Item = JSON.stringify(dataObjAlt.Com_Item);
		}
		if (
			dataObjAlt.Com_FileUrl instanceof Array ||
			dataObjAlt.Com_FileUrl instanceof Object
		) {
			dataObjAlt.Com_FileUrl = JSON.stringify(dataObjAlt.Com_FileUrl)
		}
		return axios({
			url: `sceua/AppraiseAutoAudit/CreateEditSave`,
			method: "post",
			data: {
				...dataObjAlt
			},
		})
	},
	getByKey(id) {
		return axios({
			url: `sceua/AppraiseAutoAudit/GetByKey?id=${id}`,
			method: "post"
		})
	},
  getDQGetByKey(id){
		return axios({
			url: `sceua/AppraiseAutoGetDate/GetByKey?id=${id}`,
			method: "post"
		})
  },
  getDQGetByKey2(data){
		return axios({
			url: `sceua/AppraiseAutoAudit/GetByKeyAudNo?appraiseComId=${data.appraiseComId}&audNo=${data.audNo}`,
			method: "post"
		})
  },
  saveDQSaveAudit(data){
		return axios({
			url: `sceua/AppraiseAutoGetDate/SaveAudit?auditContent=${data.auditContent}&score=${data.score}&id=${data.id}`,
			method: "post"
		})
  },
	getCompany(appid) {
		return axios({
			url: `sceua/AppraiseCompany/GetByKey?appraiseComId=${appid}`,
			method: "post"
		})
	},
	getAppraiseAudsItemQuery(data) {
		return axios({
			url: `sceua/AppraiseAudsItem/Query`,
			method: "post",
			data
		})
	},
	saveComAutoAuditFile(data) {
		return axios({
			// url: `sceua/AppraiseCompany/SaveComAutoAuditFile?appcomid=${appcomid}`,
      url:`sceua/Upload/file?path=${data.path}&secondPath=${data.secondPath}&fileName=${data.fileName}`,
			method: "post",
			data: data.file,
			headers: {
				'Content-Type': "multipart/form-data"
			},
			processData: false,
		})
	},
	saveSaveAudit(data) {
		return axios({
			url: `sceua/AppraiseAutoAudit/SaveAudit`,
			method: "post",
			data
		})
	},
	saveAudit3(data) {
		return axios({
			url: `sceua/AppraiseAutoAudit/SaveAudit3`,
			method: "post",
			data
		})
	},
	saveDisAgree(data) {
		return axios({
			url: `sceua/AppraiseAutoAudit/DisAgree?id=${data.id}&remark=${data.remark}`,
			method: "post"
		})
	},
	getByAppraiseCompanyId(appid) {
		return axios({
			url: `sceua/AppraiseAutoAudit/GetByAppraiseCompanyId?appraiseComId=${appid}`,
			method: "post"
		})
	},
	getQueryActiveDateAudits(data) {
		return axios({
			url: `sceua/AppraiseAutoAudit/QueryActiveDateAudits`,
			method: "post",
			data
		})
	},

	changeCompanyPublicLevel(params) {
		return axios({
			url: `sceua/AppraiseCompany/EditCompanyLevel`,
			method: "post",
			params
		})
	},
	saveAppraiseAutoAudit2(flag, dataObj) {
		if (flag === 'right') {
			return axios({
				url: `sceua/AppraiseAutoAudit/SaveAudit2`,
				method: "post",
				data: dataObj
			})
		} else {
			const dataObjAlt = {
				...dataObj
			}
			if (
				dataObjAlt.Com_Selected instanceof Array ||
				dataObjAlt.Com_Selected instanceof Object
			) {
				dataObjAlt.Com_Selected = JSON.stringify(dataObjAlt.Com_Selected);
			}
			if (
				dataObjAlt.Com_Inputs instanceof Array ||
				dataObjAlt.Com_Inputs instanceof Object
			) {
				dataObjAlt.Com_Inputs = JSON.stringify(dataObjAlt.Com_Inputs);
			}
			if (
				dataObjAlt.Com_Item instanceof Array ||
				dataObjAlt.Com_Item instanceof Object
			) {
				dataObjAlt.Com_Item = JSON.stringify(dataObjAlt.Com_Item);
			}
			if (
				dataObjAlt.Com_FileUrl instanceof Array ||
				dataObjAlt.Com_FileUrl instanceof Object
			) {
				dataObjAlt.Com_FileUrl = JSON.stringify(dataObjAlt.Com_FileUrl)
			}
			return axios({
				url: `sceua/AppraiseAutoAudit/SaveAudit2`,
				method: "post",
				data: {
					...dataObjAlt
				},
			})
		}
	},
	saveRepairFile(data) {
		return axios({
			// url: `sceua/AppraiseCompanyRequestRepair/SaveRepairFile?appcomid=${appcomid}&ItemSerial=${ItemSerial}`,
      url:`sceua/Upload/file?path=${data.path}&secondPath=${data.secondPath}&fileName=${data.fileName}`,
			method: "post",
			data: data.file,
			headers: {
				'Content-Type': "multipart/form-data"
			},
			processData: false,
		})
	},
	saveDataEditSave(data) {
		return axios({
			url: `sceua/AppraiseCompanyRequestRepair/CreateEditSave`,
			method: "post",
			data
		})
	},
	getByKeys(id) {
		return axios({
			url: `sceua/AppraiseCompanyRequestRepair/GetByKey?id=${id}`,
			method: "post"
		})
	},
	saveAuditContent(data) {
		return axios({
			url: `sceua/AppraiseCompanyRequestRepair/SaveAudit?auditContent=${data.auditContent}&status=${data.status}&id=${data.id}&score=${data.score}`,
			method: "post"
		})
	},
	saveAuditContent2(data) {
		return axios({
			url: `sceua/AppraiseCompanyRequestRepair/SaveAudit2?auditContent=${data.auditContent}&status=${data.status}&id=${data.id}&score=${data.score}`,
			method: "post"
		})
	},
	exportFiles(url) {
		return axios({
			url,
			method: "get",
			data: {},
			responseType: "blob"
		})
	},

	getComAudInfo(appraiseComId) {
		return axios({
			url: `sceua/AppraiseCompany/GetByKey`,
			method: "post",
			params: {
				appraiseComId
			}
		})
	},

  getHistory(data) {
		return axios({
			url: `sceua/AppraiseAutoAudit/QueryAuditedHistory`,
			method: "post",
			data
		})
	},
	updateProNameCode(data) {
		return axios({
			url: `sceua/AppraiseCompany/UpdateProNameCode`,
			method: "post",
			data
		})
	},
	submitToEnd(appcomid) {
		return axios({
			url: `sceua/AppraiseCompany/SubmitEndToAuto?appcomid=${appcomid}`,
			method: "post"
		})
	},
	getAuditionSubTree(id) {
		return axios({
			url: `sceua/AppraiseAudsItem/QueryItemTree?id=${id}`,
			method: "post"
		})
	},
}
export default reviewApi
